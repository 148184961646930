import { device } from "../../layout/global-styles/device";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

const Heading = styled.h2`
    margin-bottom: 0.5rem;
    text-align: center;
    text-transform: uppercase;
    font-family: "nouvelr-bold";
    font-size: 2rem;
    word-wrap: break-word;
    max-width: 300px;

    @media ${device.tablet} {
        max-width: 800px;
    }
    @media ${device.laptop} {
        max-width: none;
    }
`;
const Line = styled.div`
    width: 10rem;
    height: 2px;
    margin: auto;
    background-color: #efdf00;
`;
const Wrapper = styled.div<TitleStyledComponent>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: auto;
    margin-top: ${(props) => (props.isTopModel ? "3rem" : "5rem")};
    margin-bottom: 0.5rem;

    .desktopHeading {
        display: none;

        @media ${device.mobileL} {
            display: block;
        }
    }

    .mobileHeading {
        display: block;

        @media ${device.mobileL} {
            display: none;
        }
    }
`;
interface TitleStyledComponent {
    isTopModel?: boolean;
    headingMobile?: boolean;
}
interface TitleProps {
    heading: string;
    headingMobile?: string | null;
    isTopModel?: boolean;
    noLine?: boolean;
}

const Title = ({
    heading,
    isTopModel = false,
    noLine = false,
    headingMobile = null,
}: TitleProps) => {
    return (
        <Wrapper isTopModel={isTopModel}>
            {headingMobile ? (
                <>
                    <Heading className={"desktopHeading"}>
                        {ReactHtmlParser(heading)}
                    </Heading>
                    <Heading className={"mobileHeading"}>
                        {ReactHtmlParser(headingMobile)}
                    </Heading>
                </>
            ) : (
                <Heading>{ReactHtmlParser(heading)}</Heading>
            )}
            {!noLine && <Line />}
        </Wrapper>
    );
};

export default Title;
