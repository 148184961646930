import React from "react";

interface ChevronRightProp {
    colorTint: string;
}

const ChevronRight: React.FC<ChevronRightProp> = ({
    colorTint,
}: ChevronRightProp) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.449"
                height="23.151"
                viewBox="0 0 13.449 23.151"
            >
                <g
                    id="Group_103"
                    data-name="Group 103"
                    transform="translate(-1000.775 -8136.924)"
                >
                    <line
                        id="Line_112"
                        data-name="Line 112"
                        x1="10"
                        y1="11"
                        transform="matrix(0.985, -0.174, 0.174, 0.985, 1001.621, 8139.452)"
                        fill="none"
                        stroke={colorTint}
                        strokeWidth="2"
                    />
                    <line
                        id="Line_222"
                        data-name="Line 222"
                        x1="10"
                        y2="11"
                        transform="matrix(0.985, 0.174, -0.174, 0.985, 1003.531, 8146.715)"
                        fill="none"
                        stroke={colorTint}
                        strokeWidth="2"
                    />
                </g>
            </svg>
        </>
    );
};

export default ChevronRight;
