import { useQuery } from "@apollo/client";
import { gql } from "apollo-boost";
import StaticApolloClient from "../StaticApolloClient";
export interface TestDriveCarInfo {
    slug?: string;
    name?: string;
    leadName?: string;
    testDriveImage?: string;
    formPlacement?: string;
}

const TESTDRIVE_CARINFO_QUERY = (id) => gql`
query MyQuery {
    carList(id: "${id}", idType: SLUG) {
      slug
      carListACF {
        testDriveBackgrounds {
          tdUltrawide {
            mediaItemUrl
          }
        }
        cleanName
        formPlacement
        leadsName
      }
    }
  }
`;

const transform = (carInfoData) => {
    let carInfoReturn: TestDriveCarInfo;

    if (
        carInfoData &&
        carInfoData.data &&
        carInfoData?.data?.carList?.carListACF
    ) {
        const node = carInfoData?.data?.carList?.carListACF;
        carInfoReturn = {
            slug: carInfoData?.data?.carList?.slug,
            name: node.cleanName,
            leadName: node.leadsName,
            testDriveImage: null,
            formPlacement: null,
        };

        const imgNode = node.testDriveBackgrounds.tdUltrawide;
        if (imgNode && imgNode !== null && imgNode.mediaItemUrl) {
            carInfoReturn.testDriveImage =
                node.testDriveBackgrounds.tdUltrawide.mediaItemUrl;
        }

        const formPlacementNode = node.formPlacement;

        if (formPlacementNode && formPlacementNode !== null) {
            carInfoReturn.formPlacement = node.formPlacement;
        }

        return carInfoReturn;
    }

    return null;
};

export const GetTestDriveCarInfo = (id) => {
    const carInfoData = useQuery(TESTDRIVE_CARINFO_QUERY(id));
    return transform(carInfoData);
};

export const GetTestDriveCarInfoSSR = async (id) => {
    const data = await StaticApolloClient.query({
        fetchPolicy: "no-cache",
        query: TESTDRIVE_CARINFO_QUERY(id),
    });
    return transform(data);
};
