export namespace formatters {
    export const numberFormatter = (n: string | number) => {
        return numberWithSpaces(n);
    };

    export const stripHtmlTags = (c: string) => {
        return c.replace(/(<([^>]+)>)/gi, " ");
    };

    const numberWithSpaces = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };
}
