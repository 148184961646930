import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "../../layout/global-styles/device";
import { ICarlistData } from "../../lib/queries/CarlistQuery";
import Title from "../Elements/Title";
import HighlightCar from "../HighlightCar";
import { componentTypes } from "../PageOvertake";
import CarsList from "./CarsList";

interface OurCarsData {
    categories: string[];
    subCategories: string[];
    cars: ICarlistData[];

    // Selected
    category: string;
    subCategory?: string;
}
interface OurCarsProps {
    data: OurCarsData;
    frontpageData?: any;
    // Overtake
    componentType?: componentTypes;
    // Conditional title text
    title?: string;
}

const StyledWrapper = styled.section`
    max-width: 1008px;
    margin: 0 auto;
    padding: 0 2rem;

    @media ${device.tablet} {
        padding: 0 1rem;
    }

    @media ${device.laptopL} {
        max-width: 1440px;
        padding: 0;
    }
`;

const List = styled.ul`
    font-family: "nouvelr-bold";
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
        color: ${(props) => props.theme.medium};
        margin: 0 1rem;
        transition: 200ms ease;

        &:hover {
            cursor: pointer;
            color: ${(props) => props.theme.black};
        }
    }

    .active-category {
        color: ${(props) => props.theme.black};
        border-bottom: 1px solid ${(props) => props.theme.primary};
    }
`;

const Category = styled.div`
    margin-top: 2rem;

    li {
        text-transform: uppercase;
        font-size: 1.5em;
    }
`;

const SubCategory = styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;
    li {
        font-size: 1.5em;
    }
`;

const OurCars: React.FC<OurCarsProps> = ({
    data,
    componentType = "product",
    title = undefined,
    frontpageData = null,
}: OurCarsProps) => {
    const { categories, subCategories, cars } = data;
    const subCat = subCategories.filter((word) => {
        return word !== "hybrid" && word !== "plug-in hybrid";
    });
    const [category, setCategory] = useState(data.category);
    const [sub, setSubCategory] = useState(data.subCategory || "alle");

    const [activeTags, setTags] = useState({
        primary: category,
        secondary: sub,
    });

    useEffect(() => {
        if (activeTags.primary !== category || activeTags.secondary !== sub) {
            setTags({ primary: category, secondary: sub });
        }
    }, [activeTags, category, sub]);

    /**
     * Varebil only has two sub categories (all, elbil).
     * Personbil has four, so we slice it away below.
     */
    const filteredSubCategories = subCat.slice(
        0,
        activeTags.primary === "personbil" ? 4 : 2
    );

    const setLimit = () => {
        if (componentType === "testdrive" || componentType === "videoviewing") {
            return 40;
        } else {
            return 40;
        }
    };

    const router = useRouter();
    const frontPage = router.asPath;

    return (
        <StyledWrapper>
            {title && <Title heading={title} isTopModel={true} />}
            {frontPage === "/" && frontpageData && (
                <HighlightCar
                    data={frontpageData}
                    size={{ width: 1000, height: 610 }}
                    frontpage={true}
                />
            )}
            <Category>
                <List>
                    {categories.map((c) => (
                        <li
                            onClick={() => {
                                setCategory(c), setSubCategory("alle");
                            }}
                            key={c}
                            className={c == category ? "active-category" : ""}
                        >
                            {c}
                        </li>
                    ))}
                </List>
            </Category>

            <SubCategory>
                <List>
                    {filteredSubCategories.map((s) => (
                        <li
                            className={s == sub ? "active-category" : ""}
                            onClick={() => setSubCategory(s)}
                            key={s}
                        >
                            {s}
                        </li>
                    ))}
                </List>
            </SubCategory>
            <CarsList
                cars={cars}
                filterTags={activeTags}
                limit={setLimit()}
                componentType={componentType}
            />
            {/* {notFullView && (
                <MoreCarsButtonWrapper
                    active={showMore}
                    onClick={() => setShowMore(!showMore)}
                >
                    <MoreButton />
                </MoreCarsButtonWrapper>
            )} */}
        </StyledWrapper>
    );
};

export default OurCars;
