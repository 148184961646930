import styled from "styled-components";
import React, { useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";

import ChevronRight from "../icons/ChevronRight";
import { ICarlistData } from "../../lib/queries/CarlistQuery";
import PageOvertake, { componentTypes } from "../PageOvertake";
import { formatters } from "../../lib/formatters";
import { GetDealers } from "../../lib/queries/DealerQuery";
import { GetTestDriveCarInfo } from "../../lib/queries/TestDriveCarInfoQuery";
import ReactHtmlParser from "react-html-parser";
import { device } from "../../layout/global-styles/device";
import { DataLayerPush } from "../../lib/tracking/DataLayer";

interface CarCardProps {
    car: ICarlistData;
    componentType?: componentTypes;
}

const CarImage = styled.div`
    cursor: pointer;
    position: relative;
    left: -15%;
    .hover-text {
        color: white;
        display: flex;
        align-items: center;
        text-align: center;
        position: absolute;
        max-width: 345px;
        max-height: 258px;
        font-size: 2.5em;
        text-overflow: wrap;
        width: 100%;
        height: 100%;
    }
    video {
        max-width: 345px;
        max-height: 258px;
    }
`;

const CarInfoStyled = styled.div`
    padding: 0.7rem;
    width: 100%;
    h3 {
        font-size: 1.25em;
        margin-bottom: 0.2em;
        text-transform: uppercase;
    }
    p {
        font-size: 0.9375em;
        span {
            margin-left: 0.1em;
            font-weight: bold;
        }
    }
`;

const Link = styled.a`
    text-decoration: none;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.black};
    cursor: pointer;

    span {
        padding-right: 1rem;
        background: transparent;
        border: none;
        font-weight: bold;
        font-size: 1.25em;
        color: black;
    }
`;

const SubCategoryMarker = styled.div`
    border: 1px solid ${(props) => props.theme.blue};
    border-radius: 2px;
    padding: 3px 5px;
    width: fit-content;
    margin-bottom: 5px;
    margin-top: -25px;
`;

const SubCategoryMarkerText = styled.p`
    color: ${(props) => props.theme.blue};
`;

const Cta = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.8em;
    text-transform: lowercase;
`;

const CardWrapper = styled.div`
    max-width: 345px;
    margin-left: 15%;
    @media ${device.tablet} {
        margin-left: 2%;
    }
    @media ${device.laptop} {
        margin-left: 0;
    }
`;

const EquipmentList = styled.div`
    padding-top: 1rem;
`;

const CarCard: React.FC<CarCardProps> = ({
    car,
    componentType = "product",
}: CarCardProps) => {
    const [equipmentActive, setEquipmentActive] = useState(false);
    const [hover, setHover] = useState(false);

    const router = useRouter();
    const dealers = GetDealers();
    const testDriveSlug = GetTestDriveCarInfo(car.slug);
    const [showModal, setShowModal] = useState(router.query.slug === car.slug);

    if (!car) {
        return null;
    }

    const setCta = () => {
        if (componentType === "testdrive") return "Prøvekjør";
        if (componentType === "videoviewing") return "Avtal";
        if (componentType === "product") return "Utforsk";
        else {
            if (car.equipmentList) return "Vis utstyr";
            else return "Utforsk";
        }
    };

    const isModalLink = () => {
        if (componentType === "testdrive" || componentType === "videoviewing") {
            return true;
        } else {
            return false;
        }
    };

    const setHref = () => {
        if (isModalLink() || car.teaser) {
            return null;
        }
        if (car.link && !car.setLink) {
            return car.link;
        }
        if (car.setLink) {
            return car.setLink;
        }
        return null;
    };

    const handleClick = (event) => {
        // Only send this event when we are selecting in the test drive listing
        if (router.pathname.match("/pr%C3%B8vekj%C3%B8r")) {
            DataLayerPush(window, {
                car: car.name,
                step: "Select car",
                event: "testDrive_step_1",
            });
        }

        if (!isModalLink()) {
            if (car.equipmentList) {
                event.preventDefault();
                setEquipmentActive(!equipmentActive);
            }
        }
    };

    const handleModalClick = (event) => {
        if (isModalLink()) {
            event.preventDefault();
            setShowModal(true);
            router.query.slug = car.slug;
            const base =
                componentType === "testdrive"
                    ? "prøvekjør"
                    : "avtal-en-videovisning";
            router.push(`/${base}/${car.slug}?slug=${car.slug}`);
        }
    };

    return (
        <>
            {
                <CardWrapper
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <Link
                        href={setHref()}
                        onClick={(event) => {
                            handleClick(event);
                            handleModalClick(event);
                        }}
                    >
                        <CarImage>
                            {car.video && (
                                <video poster={car.image} autoPlay muted loop>
                                    <source src={car.video} type="video/mp4" />
                                </video>
                            )}
                            {car.hoverText && hover && (
                                <div className="hover-text">
                                    {car.hoverText}
                                </div>
                            )}

                            {!car.video && (
                                <Image
                                    unoptimized={true}
                                    priority={true}
                                    alt={`bilde ${car.name}`}
                                    width="345px"
                                    height="200px"
                                    src={car.image}
                                    objectFit="contain"
                                    quality={90}
                                    placeholder="blur"
                                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                />
                            )}
                        </CarImage>

                        <CarInfoStyled>
                            {car.tags.map((tag, i) => {
                                if (
                                    tag !== "personbiler" &&
                                    tag !== "varebiler"
                                ) {
                                    return (
                                        <SubCategoryMarker key={i + 1}>
                                            <SubCategoryMarkerText>
                                                {tag}
                                            </SubCategoryMarkerText>
                                        </SubCategoryMarker>
                                    );
                                }
                            })}

                            <h3>{ReactHtmlParser(car.name)}</h3>
                            {car.campaignPrice ? (
                                <p>
                                    Kampanjepris fra{" "}
                                    <span>
                                        {formatters.numberFormatter(
                                            car.campaignPrice
                                        )}
                                        ,-
                                    </span>
                                </p>
                            ) : (
                                <p>
                                    {car.price && (
                                        <>
                                            Pris fra{" "}
                                            <span>
                                                {formatters.numberFormatter(
                                                    car.price
                                                )}
                                                ,-
                                            </span>
                                        </>
                                    )}

                                    {!car.price && <>Pris kommer</>}
                                </p>
                            )}
                            {!car.teaser && (
                                <Cta>
                                    <span>{setCta()}</span>
                                    <ChevronRight colorTint={"#EFDF00"} />
                                </Cta>
                            )}

                            {car.equipmentList && equipmentActive && (
                                <EquipmentList>
                                    {ReactHtmlParser(car.equipmentList)}
                                </EquipmentList>
                            )}
                        </CarInfoStyled>
                    </Link>
                </CardWrapper>
            }
            {isModalLink() && testDriveSlug && (
                <PageOvertake
                    data={dealers}
                    carData={testDriveSlug}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    componentType={componentType}
                />
            )}
        </>
    );
};

export default CarCard;
