import styled from "styled-components";
import Link from "next/link";
import ReactHtmlParser from "react-html-parser";
import SuperPrimaryButton from "./Elements/SuperPrimaryButton";
import { device } from "../layout/global-styles/device";
import Image from "next/image";
import PrimaryButton from "./Elements/PrimaryButton";

interface HighlightCarStyle {
    frontpage?: boolean;
    ownerspage?: boolean;
}

const HighlightCar = ({
    data,
    size,
    frontpage = false,
    ownerspage = false,
}) => {
    const { image, cta, secondCta, text, title, price } = data;
    const { width, height } = size;

    return (
        <Wrapper frontpage={frontpage}>
            <FlexBG>
                <Image
                    unoptimized={true}
                    src={image}
                    alt={title}
                    width={width}
                    height={height}
                />
                <FlexContent ownerspage={ownerspage}>
                    <Title ownerspage={ownerspage}>
                        {ReactHtmlParser(title)}
                    </Title>
                    <Price>{ReactHtmlParser(price)}</Price>
                    <Text>{ReactHtmlParser(text)}</Text>
                    <CtaWrapper>
                        {secondCta.href && (
                            <Link href={secondCta.href} passHref>
                                <SecondCta>
                                    <PrimaryButton text={secondCta.text} />
                                </SecondCta>
                            </Link>
                        )}
                        <Link href={cta.href}>
                            <a>
                                <SuperPrimaryButton text={cta.text} />
                            </a>
                        </Link>
                    </CtaWrapper>
                </FlexContent>
            </FlexBG>
        </Wrapper>
    );
};

export default HighlightCar;

const Wrapper = styled.div<HighlightCarStyle>`
    max-width: 1440px;
    margin: ${(props) => (props.frontpage ? "1rem auto" : "4rem auto")};
`;

const FlexBG = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: ${(props) => props.theme.light};
    padding: 1rem;

    @media ${device.tablet} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 4rem 6rem;
    }
`;

const FlexContent = styled.div<HighlightCarStyle>`
    min-width: 55%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.ownerspage ? "center" : "stretch")};
    text-align: ${(props) => (props.ownerspage ? "center" : "left")};

    @media ${device.tablet} {
        padding: 0 3rem;
        text-align: left;
        align-items: stretch;
    }
`;

const Title = styled.h3<HighlightCarStyle>`
    padding: 1rem 0 0.5rem;
    font-family: "nouvelr-bold";
    font-size: 1.8rem;
    text-transform: ${(props) => (props.ownerspage ? "none" : "uppercase")};
`;

const Price = styled.p`
    padding: 0 0 1rem;
    font-family: "nouvelr-bold";
    font-size: 1.4rem;
`;

const Text = styled.p`
    font-size: 1.1rem;
    padding-bottom: 1rem;
`;

const CtaWrapper = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    @media ${device.tablet} {
        margin-top: 5px;
    }
    @media ${device.laptop} {
        flex-direction: row;
    }
`;

const SecondCta = styled.a`
    margin-right: 15px;
    padding-bottom: 10px;

    @media ${device.tablet} {
        margin-bottom: 0px;
    }
`;
