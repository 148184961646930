import React from "react";
import styled from "styled-components";
import { device } from "../../layout/global-styles/device";

import { ICarlistData } from "../../lib/queries/CarlistQuery";
import { componentTypes } from "../PageOvertake";
import CarCard from "./CarCard";

interface CarsListProps {
    filterTags?: {
        primary: string;
        secondary: string;
    };
    cars: ICarlistData[];
    limit;
    componentType?: componentTypes;
}

const StyledList = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1008px;
    margin: 0 auto;

    @media ${device.laptopL} {
        max-width: 1440px;
        margin: 0 auto;
    }
`;

const CarsList: React.FC<CarsListProps> = ({
    filterTags,
    cars,
    limit,
    componentType = "product",
}: CarsListProps) => {
    const _filtered = cars
        .filter((c) => {
            if (filterTags) {
                if (filterTags.secondary === "alle") {
                    // Filter on the main category
                    return c.tags.includes(filterTags.primary + "er");
                }
                return (
                    c.tags.includes(filterTags.primary + "er") &&
                    (c.tags.includes(filterTags.secondary) ||
                        c.tags.includes(filterTags.secondary + "er"))
                );
            }
            return true;
        })
        .slice(0, limit);

    return (
        <StyledList>
            {(componentType == "testdrive" ||
                componentType == "videoviewing") &&
                _filtered.map((c) => {
                    if (!c.noListTestDrive) {
                        return (
                            <CarCard
                                key={c.name}
                                car={c}
                                componentType={componentType}
                            />
                        );
                    }
                })}
            {componentType == "product" &&
                _filtered.map((c) => {
                    if (!c.noList) {
                        return (
                            <CarCard
                                key={c.name}
                                car={c}
                                componentType={componentType}
                            />
                        );
                    }
                })}
        </StyledList>
    );
};

export default CarsList;
